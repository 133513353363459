import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, shareReplay, map, take } from 'rxjs/operators';
import { Configuration } from '../../../core/models/configsetting.model';
import { ConfigurationSettingsService } from '../configurationsettings/configurationsettings.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigFeatureFlagSettingsManager {
    private configurationSubject = new BehaviorSubject<Configuration>({ configs: {}, features: {} });
    private configuration$: Observable<Configuration>;

    constructor(private configFeatureFlagService: ConfigurationSettingsService) {
        this.initializeConfiguration();
    }

    /**
     * Initialize or refresh the configuration observable.
     */
    private initializeConfiguration(): void {
        this.configuration$ = this.configFeatureFlagService.getConfigFeatureFlags().pipe(
            shareReplay(1),
            catchError(error => {
                console.error('Error fetching configuration:', error);
                return of({ configs: {}, features: {} });
            })
        );

        // Update the BehaviorSubject with the new configuration
        this.configuration$.pipe(take(1)).subscribe(config => {
                this.configurationSubject.next(config)
        });
    }

    /**
     * Get a specific config value.
     */
    configSetting(key: string): string {
        const currentConfig = this.configurationSubject.getValue();
        return currentConfig.configs[key] || '';
    }

    /**
     * Get a specific feature flag value.
     */
    featureFlag(key: string, defaultValue: boolean = false): boolean {
        const currentConfig = this.configurationSubject.getValue();
        const flagValueBool = currentConfig?.features[key]?.trim().toLowerCase() === 'true' ? true : false
        return flagValueBool;
    }

    /**
     * Observable version of featureFlag for async operations.
     */
    featureFlagAsync(key: string, defaultValue: boolean = false): Observable<boolean> {
        return this.configurationSubject.asObservable().pipe(
            map(config => {
                const flagValue = config?.features[key]?.trim().toLowerCase() === 'true' ? true : false;
                return flagValue;
            })
        );
    }

    /**
     * Expose the configuration observable for components.
     */
    getConfiguration(): Observable<Configuration> {
        return this.configuration$;
    }

    /**
     * Refresh configuration when tenant changes.
     */
    refreshConfigAsTenantChanged(): void {
        this.initializeConfiguration();
    }

    /**
     * Check if configuration is loaded.
     */
    isConfigurationLoaded(): boolean {
        const currentConfig = this.configurationSubject.getValue();
        return currentConfig?.features && Object.keys(currentConfig.features).length > 0;
    }
}
