import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../../core/models/configsetting.model';
import { catchError,map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationSettingsService {

    constructor(private http: HttpClient) { }

    getConfigFeatureFlags(): Observable<any> {
        const url = '/tr-api/configuration/list';
        //console.log('Fetching configuration from URL:', url);

        return this.http.get<Configuration>(url).pipe(
            map(object => {

                // Add fallbacks in case configs or features are null
                const configs = object?.configs || {};
                const features = object?.features || {};

                //console.log('Processed configuration:', { configs, features });

                return { configs, features };
            }),
            catchError(error => {
                console.error('Error fetching configuration:', error);
                // Provide fallback in case of error
                return of({ configs: {}, features: {} });
            })
        );
    }

}
