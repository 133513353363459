/// <reference path="core/services/tokenvalidate/tokenvalidation.service.ts" />
import { Component, OnInit, OnDestroy, ViewChild, Inject, Renderer2, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { User } from './core/models/dealer.model';
import { DataService } from '@tr/target-retail';
//import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, fromEvent, merge } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { buildinfo } from '../environments/buildinfo';
import { TokenvalidationService } from './core/services/tokenvalidate/tokenvalidation.service';
import { TermsConditionService } from './core/services/termsConditions/terms-condition.service';
import { ResourceService } from './core/services/resource/resource.service';
import { ConfigurationService } from './core/services/configuration/configurations.service';
import { ConfigFeatureFlagSettingsManager } from './shared/services/configurationsettings/configFeatureFlagSetting.manager'
import { FeatureFlagKeys } from './shared/FeatureFlag'
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    isAuthenticated: boolean;
    currentUser: User;
    subscription: Subscription;
    campaignSubscription: Subscription;
    termsConditionMessage: string;
    internalRoute: boolean;
    resources: any;
    lastSessionRenewTime: any;
    currentTime: any;
    ResetCount: number = 0;
    @ViewChild('terms_condition', { static: false }) private termAndConditionContent;

    constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private router: Router, private dataService: DataService,
        private spinner: NgxSpinnerService, private modalService: NgbModal,
        private termsConditionService: TermsConditionService, private resourceService: ResourceService, private configurationService: ConfigurationService,
        private configFeatureFlagSettingsManager: ConfigFeatureFlagSettingsManager) {

        const routePath = window.location.pathname.replace(/\\|\//g, '');
        this.spinner.show();
        DataService.packageBaseUrl = "/tr-analytics-api";
        DataService.campaignBuilderUrl = "/tr-api/Campaign/Execute";
        DataService.assetsBasePath = "/dist/assets/core/";
        DataService.weDamapiUrl = environment.webEnrollmentDAMAPI;
        DataService.dealerId = TokenvalidationService.tokenInformation["PartDealerId"] ? TokenvalidationService.tokenInformation["PartDealerId"] : '';
        DataService.oemId = TokenvalidationService.tokenInformation["DHOEMId"] ? TokenvalidationService.tokenInformation["DHOEMId"] :
            environment.dataHubOEMS[TokenvalidationService.tenant];

        if (!this.internalRoute) {
            DataService.selectedMenu = routePath;
            let navigationInfo = {
                eventType: "NavigationMenu",
                MenuName: routePath
            };
            parent.parent.postMessage(navigationInfo, '*');
        }
        DataService.sessionObject = TokenvalidationService.tokenInformation;
        DataService.sessionObject.trCoreApi = "/tr-api/";
        DataService.sessionObject.trAnalyticsApi = "/tr-analytics-api/";
        DataService.sessionObject.cbApi = "/cb-api/";

        if (TokenvalidationService.tokenInformation['brand'] != null) {
            DataService.brandList = (TokenvalidationService.tokenInformation['brand'] == '') ? [''] : TokenvalidationService.tokenInformation['brand'].split();
        }

        if (!environment.production) {
            console.log("AppURL" + this.router.url);
            console.log("Selected Route is : " + routePath);
            console.log(`DataService parameters dealerId: ${TokenvalidationService.tokenInformation["PartDealerId"]}  oemId: ${TokenvalidationService.tokenInformation["DHOEMId"]}`)
            console.log(DataService.brandList);
            console.log("Token info", TokenvalidationService.tokenInformation);
        }

        if (environment.conquestEnrollmentConditions[TokenvalidationService.tenant]) {
            let _contractOptions = TokenvalidationService.tokenInformation['ContractOptions'] ? TokenvalidationService.tokenInformation['ContractOptions'].toLowerCase().replace(/\s/g, '') : "";
            let _contractCondition = TokenvalidationService.tokenInformation['ContractCondition'] ? TokenvalidationService.tokenInformation['ContractCondition'].toLowerCase().replace(/\s/g, '') : "";
            let _contractStatus = TokenvalidationService.tokenInformation['ContractStatus'] ? TokenvalidationService.tokenInformation['ContractStatus'].toLowerCase().replace(/\s/g, '') : "";

            TokenvalidationService.creativeType = _contractOptions;
            DataService.dealerConquestEnrollment = (_contractOptions === "ownersandconquest") ? true : false;
            DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "NA";

            if ((_contractOptions === "owners" && _contractCondition === "activecontract" && _contractStatus === "stop")) {
                DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "UP";
            }
            else if ((_contractOptions === "ownersandconquest" && _contractCondition === "activecontract" && _contractStatus === "stop")) {
                DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "DP";
                TokenvalidationService.creativeType = "owners";
            }
            else if ((_contractOptions === "ownersandconquest" && _contractCondition === "activecontract" && _contractStatus === "active")) {
                DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "A";
            }
            else if ((_contractOptions === "owners" && _contractCondition === "activecontract" && _contractStatus === "active")) {
                DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "NA";
            }
        }
        else {
            DataService.dealerConquestEnrollment = true;
            DataService.conquestEnrollmentStatus = TokenvalidationService.conquestEnrollmentStatus = "A";
            DataService.brandList = [TokenvalidationService.tenant.replace('TR', '')];
            TokenvalidationService.creativeType = "";
        }

        this.campaignSubscription = this.dataService.campaignInfoEmitter.subscribe((result) => {
            if (result) {
                console.log(result);
                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                    return false;
                }
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['campaign/' + result.templateId])
            }
        })
        this.subscription = this.dataService.tabChangeEmitter.subscribe((result) => {
            if (result) {
                let navigationInfo = {
                    eventType: "NavigationMenu",
                    MenuName: result
                };
                if (!environment.production) {
                    console.log("ROUTE:", result);
                    console.log('lease end', this.dataService);
                }
                parent.parent.postMessage(navigationInfo, '*');
                this.internalRoute = true;
                DataService.selectedMenu = result;
                if (result.toLowerCase() != "contactus") {
                    this.router.navigate(['/' + result]);
                }
            }
        })

        setTimeout(() => {
            this.spinner.hide();
        }, 2000);
    }

    title = 'TargetRetail';

    ngOnInit(): void {
        this.resources = this.resourceService.resourceInformation.Resources;

        const mousemoveEvent = fromEvent(document, 'mousemove');
        const keyboardEvent = fromEvent(document, 'keydown');
        const allEvents = merge(
            mousemoveEvent, keyboardEvent
        )

        this.subscription = allEvents.subscribe(e => {
            this.currentTime = new Date();
            let sessionReset = {
                eventType: "sessionReset",
                time: Number(Date.now())
            };
            parent.parent.postMessage(sessionReset, '*');
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('window:message', ['$event']) onMessage(event) {
        if (event.data && event.data.eventType === 'TRNavigation') {
            if (event.data.menuName) {
                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                    return false;
                }
                this.router.onSameUrlNavigation = 'reload';
                const routePath = event.data.menuName;
                if (this.dataService) this.dataService.changeMenu(routePath);
            }
        }
    }
    //Not using
    checkWECondition() {
        this.configFeatureFlagSettingsManager.featureFlag(FeatureFlagKeys.EnableWETermsConditionsCheckKey) ? this.checkTermsCondition() : console.log("WE T&C Disabled");
        //this.configurationService.getConfigurations(TokenvalidationService.tenant, 'EnableWETermsConditionsCheck', 'cb').subscribe((response): any => {
        //    if (response && response.length > 0)
        //        response[0].value == "1" ? this.checkTermsCondition() : console.log("WE T&C Disabled");
        //});
    }
    //Not using
    checkTermsCondition() {
        const partDealerId = TokenvalidationService.tokenInformation['PartDealerId'];
        this.termsConditionService.getTermsAndConditionFlag(partDealerId).subscribe(
            (response) => {
                if (response) {
                    const termsConditionflag = (response.termsAndConditionsFlag === 'N') ? true : false;
                    this.termsConditionService.setTermsConditionStatus(termsConditionflag);
                    if (termsConditionflag) {
                        this.router.navigate(['/home']);
                        let NgbModalOptions: NgbModalOptions = {
                            backdrop: 'static',
                            keyboard: false,
                            size: 'lg'
                        }
                        this.termsConditionMessage = response.termsAndConditionsMessage;
                        this.modalService.open(this.termAndConditionContent, NgbModalOptions);
                        this.renderer.addClass(this.document.body, 'blurBackground')
                    }
                }
            }
        )
    }
    //Not using
    accpetTermsCondition() {
        this.renderer.removeClass(this.document.body, 'blurBackground')
        const partDealerID = TokenvalidationService.tokenInformation['PartDealerId'];
        const attributeName = 'termsAndConditionsFlag';
        const attributeValue = 'Y';
        const user = TokenvalidationService.tokenInformation['UserName']
        if (partDealerID && attributeName && attributeValue && user) {
            this.termsConditionService.acceptTermsAndConditions(partDealerID, attributeName, attributeValue, user).subscribe(
                (response) => {
                    if (response) {
                        console.log(response);
                    }
                }
            )

        }
    }
    }
