export class GlobalVariable {
    public static tenantName: string = '';
    public static dealerName: string = '';
    public static dealerID: string = '';
    public static orgGuid: string = '';
    public static userName: string = '';
    public static culture: string = '';
    public static userGuid: string = '';
    public static brand: string = '';
    public static role: any = [];
    public static permissions: any;
    public static isGlobalAdmin: boolean = false;
    public static isImpersonated: boolean = false;
    public static dealerUserType: string[] = ['cccampaignauthor', 'ccdealeradmin', 'ccviewer','dealer'];
}


