import {
    HttpHandler,
    HttpRequest,
    HttpInterceptor,
    HttpHeaders,
    HttpEvent
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs"
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    httpRequest: HttpRequest<any>;
    constructor(
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let tenant = sessionStorage.getItem('tenant');
        let businessEntity = sessionStorage.getItem('businessEntity');

        if (request.url === '/tr-api/configuration/list') {
            this.httpRequest = request.clone({
                setHeaders: {
                    'X-EA-Tenant': tenant ? tenant : '',
                    'X-EA-BusinessEntity': businessEntity ? businessEntity : ''
                }
            });
            return next.handle(this.httpRequest);
        }
        else {
            return next.handle(request);
        }

        return next.handle(request);
    }
}
